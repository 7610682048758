* {
  box-sizing: border-box;
}

.invoice-container {
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.item-container-small-screen {
  box-shadow:
    rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.items-row {
  padding-right: 0.5rem !important;
  padding-left: 0.2rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.tc-user-modal-header h3 {
  padding-top: 8px !important;
}

.tc-user-modal-header button {
  margin: 7px 7px 0 0;
}

.tc-async-select .css-13cymwt-control {
  box-shadow: navy;
}

.tc-async-select .css-13cymwt-control:focus {
  box-shadow: red !important;
  border-color: none !important;
}

.tc-async-select .css-13cymwt-control:hover {
  box-shadow: red !important;
  border-color: none !important;
}

.tc-dropdown span {
  display: flex;
  justify-content: space-between;
}

.tc-dropdown-svg > button > svg {
  display: none;
}

.twAddLeaveTypeModal > div {
  position: fixed;
  top: 0;
  bottom: 0;
  padding: 0;
  height: 100vh;
}

.twAddLeaveTypeModal > div > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100vh;
}
.twAddLeaveTypeModal {
}
.twAddLeaveTypeModal > div > div > div:nth-child(1) {
  height: 10%;
}

.twAddLeaveTypeModal > div > div > div:nth-child(3) {
  height: 75%;
  padding-bottom: 0;
}
.twAddLeaveTypeModal > div > div > div:nth-child(4) {
  height: 15%;
  padding: 0 0 1rem 0;
  margin: 0;
}

.glossy-modal > div > div {
  box-shadow: 0 7px 20px 5px #00000088;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  background-color: rgba(255, 255, 255, 0.22);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.6));
  backdrop-filter: blur(25px);
  z-index: 10;
}
.glossy-modal hr {
  width: 100%;
  border: none;
  border-bottom: 1px solid #88888855;
  margin-top: 0;
}
.glossy-modal-header > button[aria-label="Close"] {
  transition: 0.4s ease;
}
.glossy-modal-header > button[aria-label="Close"]:hover {
  transform: scale(1);
}
.glossy-modal-body section {
  background: none;
}
@keyframes zoomIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes zoomOut {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}

.glossy-modal.zoomIn {
  animation: zoomIn 0.8s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.glossy-modal.zoomOut {
  animation: zoomOut 0.8s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.tc-home-avatar .relative > div,
.tc-home-avatar .relative > img {
  width: 80px !important;
  height: 80px !important;
}

.tc-home-avatar .relative > div > span {
  font-size: xx-large;
}

.tc-setting-icon {
  position: absolute;
  top: 3.8rem;
  left: 0;
  margin: 0;
  margin-left: 48%;
  background: #333;
  height: 25px;
  width: 25px;
  box-shadow: 3px 1px 40px;
  border-radius: 0 0 20px 20px;
  text-align: center;
  text-align: -webkit-center;
}

.tc-setting-icon svg {
  width: 15px;
  color: white;
}

header {
  background: transparent !important;
}

header nav {
  background-color: #ffffff7d;
  position: relative;
  box-shadow: 0 10px 15px rgb(0 0 0 / 5%);
  box-sizing: border-box;
  backdrop-filter: blur(15px);
  background-blend-mode: overlay;
  display: flex;
  align-items: center;
}

.tc-gray-bg {
  background-color: var(--light-gray-color);
}
