input:disabled {
    background-color: var(--light-gray-color);
}

.tc-input {
    input:not([type='checkbox']) {
        display: inline-block;
        border-radius: 0.5rem;
        border-left: none;
        background-color: var(--light-gray-color);
        border: var(--light-gray-color);
        height: 2.6rem;
    }
    input[type="number"] {
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button 
        {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        -moz-appearance: textfield;
    }
    select {
        height: 2.6rem !important;
        border: none;
        background-color: var(--light-gray-color);
    }
}

.tc-input-with-icon {
    align-items: center;
    display: flex;

    svg {
        width: 2.6rem;
        height: 2.6rem;
        border: 1px solid var(--light-gray-color);
        padding: 0.7rem;
        display: inline-block;
        border-radius: 0.5rem 0 0 0.5rem;
        border-right: none;
        background-color: var(--light-gray-color);
    }

    input {
        display: inline-block;
        border-radius: 0 0.5rem 0.5rem 0;
        border-left: none;
        background-color: var(--light-gray-color);
        border: var(--light-gray-color);
        height: 2.6rem;
        padding-left: 0;
    }

    input:focus {
        box-shadow: none;
    }
}

.tc-input-toggle {
    display: inline-block;
    border-radius: 0.7rem;
    border-left: none;
    background-color: var(--dark-gray-color);
    border: var(--light-gray-color);
    height: 1.25rem;
    width: 2.25rem;
    &::after {
        height: 1rem;
        width: 1rem;
    }
}
