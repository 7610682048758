/* li:nth-child(.tc-left-menu-item):is(:hover, :focus) {
    border-right: 4px solid var(--secondary-color);
} */
.tc-left-menu-item {
    align-items: center;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
    font-weight: 400;
    color: #222;
    transition: 300ms ease-in-out;
    border-right: 4px solid transparent;
    display: flex;
    flex-direction: column;
    padding-right: 0.5rem !important;
}

.tc-left-menu-item svg {
    width: 1.5rem !important; 
    height: 1.5rem !important;
}

.tc-left-menu-item-active svg {
    width: 1.5rem !important; 
    height: 1.5rem !important;
    color: var(--secondary-color);
}

.tc-left-menu-item:is(:hover, :focus) {
    border-right: 4px solid var(--secondary-color);
    font-weight: 600;
}

.tc-left-menu-item:is(:hover, :focus) svg {
    color: var(--secondary-color);
}

.tc-left-menu-item-active {
    align-items: center;
    text-transform: capitalize;
    font-weight: 600;
    color: #222;
    border-right: 4px solid var(--secondary-color);
    display: flex;
    flex-direction: column;
    padding-right: 0.5rem !important;
}
