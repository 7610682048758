button:focus, button:focus-visible {
    box-shadow: none !important;
}

button.tc-btn-primary {
    background-color: var(--primary-color);
}
.slick-prev:before, .slick-next:before {
    color: var(--primary-color) !important;
    
}

button.tc-btn-dark {
    background-color: var(--dark-gray-color);
    color: var(--white-color);
    svg {
        position: relative;
        top: 2px;
    }
}

button.tc-btn-dark:hover, button.tc-btn-dark:focus {
    background-color: var(--dark-lighten-gray-color) !important;
    box-shadow: none;
}

button.tc-btn-light {
    background-color: var(--light-gray-color);
}

div[role=tablist] button[aria-selected=true]:focus, div[role=tablist] button[aria-selected=true]:focus-visible {
    border: none;   
    outline: none !important;
    -webkit-tap-highlight-color: none;
    --tw-ring-opacity: none !important;
    --tw-ring-color: white !important;
}