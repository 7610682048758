.borderLeftCard {
  border-left: 3px solid #d7d25c;
}

.yetToProcessPattern {
  background: url(../../media/yet-to-process-pattern.svg);
  padding: 5px 20px;
  margin: 5px 0 0;
}

.gradientBg {
  background: linear-gradient(180deg, #fdfbfb 0%, #ebedee 100%);
}

.darkGradientBg {
  background: linear-gradient(180deg, #fdfbfb 0%, #ebedee 100%);
}

@keyframes textGradientAnimation {
  0% {
    background-position: -200% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}

.textGradientAnimation {
  background: linear-gradient(45deg, rgba(17, 24, 39), rgba(107, 114, 128));
  background-size: 200% 200%;
  animation: textGradientAnimation 3s linear infinite;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
