div:has(> div[role="tablist"]) {
    gap: 0;
}

div[role="tablist"] {
    border: none;

    button[role="tab"] {
        color: var(--black-color);
        font-weight: 700;
        border-radius: 10px;
    }

    button[aria-selected="true"] {
        background-color: var(--light-gray-color);
        color: var(--black-color);
        font-weight: 700;
    }

    button[aria-selected="true"]:focus {
        -webkit-tap-highlight-color: none;
        outline: none !important;
    }
}