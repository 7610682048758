:root {
    --header-height: 3.8rem;
    --primary-color: #76ABAE;
    --secondary-color: #76ABAE;
    --lighten-secondary-color: #bcd6d7;
    --dark-gray-color: #31363F;
    --dark-lighten-gray-color: #424955;
    --light-gray-color: #EEEEEE;
    --black-color: #222831;
    --lighten-black-color: #d3d8e0;
    --white-color: #FFF;
}