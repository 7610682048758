.sidebar {
  transform: translateX(100%);
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  width: 21rem;
  z-index: 21;
  box-shadow: 0 0.4rem 1rem rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: column;

  transition: transform 300ms ease-out, opacity 300ms ease-out;
  overflow: auto;
}

@media only screen and (max-width: 35em) {
  .sidebar {
    width: 85%;
  }
}

.sidebar--active {
  animation: slideIn 300ms ease-out forwards;
}

.sidebaritems {
  display: grid;
  grid-template-columns: 1.3rem 1fr;
  align-items: center;
  gap: 0.4rem;
  border-radius: 0.5rem;
  text-transform: capitalize;
  font-weight: 600;
  color: #222;
  padding: 0.5rem 0.6rem;
  position: relative;
  transition: 300ms ease-in-out;
  width: 100%;
  margin-bottom: 0.2rem;
}

.sidebaritems:is(:hover, :focus) {
  background-color: var(--lighten-secondary-color);
  width: 100%;
}

.sidebaritems--active {
  background-color: var(--lighten-secondary-color) !important;
  display: grid;
  grid-template-columns: 1.3rem 1fr;
  align-items: center;
  gap: 0.4rem;
  background-color: rgb(241, 244, 250);
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  text-transform: capitalize;
  font-weight: 600;
  color: #222;
  padding: 0.5rem 0.6rem;
}

@keyframes slideIn {
  80% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.leaveSidebarListItems > li:first-child a {
  /* border: 1px solid red; */
  border-top-right-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important;
}

.leaveSidebarListItems > li:nth-child(4) a {
  /* border: 1px solid red; */
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.controlPanelSidebarListItems > li:nth-child(1) div:nth-child(2) a {
  /* border: 1px solid red; */
  border-top-right-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important;
}

.controlPanelSidebarListItems > li:nth-child(1) div:nth-child(6) a {
  /* border: 1px solid red; */
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.controlPanelSidebarListItems > li:nth-child(2) div:nth-child(2) a {
  /* border: 1px solid red; */
  border-radius: 0.5rem !important;
}

.controlPanelSidebarListItems + li {
  /* border: 1px solid red; */
  margin-bottom: 0.75rem !important;
  padding: 0.5rem 1rem !important;
  border-radius: 0.5rem !important;
}