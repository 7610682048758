.InvoicesFilter {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 180px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 9px;
  z-index: 1;
  right: 17px;
  top: 8.5rem;
  border-radius: 10px;
}

.InvoicesFilter.show {
  margin-top: 1rem;
  display: block;
}

