td,
th {
  white-space: nowrap;
}

table {
  border-radius: 10px;
  border: unset;
  background-color: none;
}

thead {
    background: var(--secondary-color);
    color: var(--white-color);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    th {
      border: none;
    }

    th:first-child {
      border-top-left-radius: 10px;
    }

    th:last-child {
      border-top-right-radius: 10px;
    }
}

tbody {
  background-color: none;
  border: none;
  tr:last-child {
    td:first-child {
      border-bottom-left-radius: 10px;
    }

    td:last-child {
      border-bottom-right-radius: 10px;
    }
  }
}