@tailwind base;
@tailwind components;
@tailwind utilities;
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 0.3rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.3rem;
  background: var(--lighten-black-color);
}

::-webkit-scrollbar-thumb:active {
  background: var(--black-color);
}

:root {
  --header-height: 3.8rem;
}

/* scroll gutter */
html {
  scrollbar-gutter: stable;
}

body {
  position: relative !important;
}

td,
th {
  white-space: nowrap !important;
}

/* --- Styling for action button  --- */
.action-toggle-btn svg {
  display: none;
}

/* --- Styling for action item like edit and delete button  --- */

.action-toggle-btn li {
  padding: 0;
}

/* --- For styling the header text of the modals --- */
.modal-header-title h3 {
  width: -webkit-fill-available;
  text-align: center;
  padding-top: 20px;
  font-size: x-large;
}

.header__height {
  height: var(--header-height);
}

/* --- For adjusting the height of the modals --- */
@media (min-width: 768px) {
  .md\:h-auto {
    height: -webkit-fill-available !important;
  }
}

/* --- Styling for side bar links when active --- */

.dashboard__sidebar {
  top: calc(0% + var(--header-height)) !important;
}

/* payslip > paysliptab,monthlyinfotab > padding between heading tabs and tab-body */
.tab-payslip-links > div > div:last-child > div {
  padding: 0 !important;
}

.slick-track {
  margin-inline: initial !important;
}

.scrollbar-hide {
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.rdrMonthAndYearPickers select {
  font-size: 12px !important;
  padding-block: 6px !important;
}

.rdrDateRangeWrapper {
  max-width: 32rem !important;
}

.rdrCalendarWrapper {
  border: 1px solid #00000029;
}

.primary-color-text {
  color: var(--primary-color);
}

.primary-border {
  border-color: var(--primary-color);
}

.tc-small-avatar, .tc-small-avatar img, .tc-small-avatar div[data-testid="flowbite-avatar-initials-placeholder"] {
  height: 2rem;
  width: 2rem;
  font-size: 0.8rem;
}

.multiline-tooltip {
  white-space: normal !important;  /* Allows text to break lines */
  word-wrap: break-word !important; /* Ensures long words are wrapped */
  max-width: 200px;                 /* Limits the tooltip width */
  color: #333;                      /* Optional: adjust text color */
}

@keyframes animate-on-click {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(4px);
  }

  100% {
    transform: translateY(0px);
  }
}
